@import "../../styles/propertySets.css";

.root {
  margin: 10px 20px 20px 35px;
  width: 100%;
  /* background-color: #ffffff; */

  @media screen and (max-width: 1640px) {
    margin: 0px 0px 20px 0px;
  }
  @media screen and (max-width: 768px) {
    margin: 0px 0px;
  }
}

.email {
  & input {
    background: #ebebeb 0% 0% no-repeat padding-box;
    border-radius: 28px;
    padding: 9px 13px 9px 20px;
    @media screen and (max-width: 768px) {
      padding: 13px 13px 13px 20px;
    }
    &::placeholder {
      font: normal normal medium 15px/20px Roboto;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
  margin-top: 20px;
}

.rowWrapper {
  display: flex;
  gap: 58px;
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 49px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
  & .inputWrapper {
    margin-top: 155px;
    max-width: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1640px) {
      max-width: 50%;
      margin-top: 100px;
    }
    @media screen and (max-width: 1023px) {
      max-width: 100%;
    }
    @media screen and (max-width: 1023px) {
      margin-top: 0px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    & .text1 {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 36px;
      font-weight: 600;
    }
  }
}

.formDetailsRight {
  position: relative;
  /* background: #F5F5F5 0% 0% no-repeat padding-box; */
  margin-top: 65px;
  @media screen and (max-width: 1920px) {
    max-width: 55%;
  }
  @media screen and (max-width: 1640px) {
    max-width: 40%;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
  & > div {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -16%;
    bottom: 0;
    background: #f5f5f5;
    transform: rotate(83deg);
    height: 1400px;
    width: 1400px;
    @media screen and (max-width: 1640px) {
      left: 8%;
    }
  }
}

.box1 {
  /* background: #f5f5f5 0% 0% no-repeat padding-box; */
  padding: 15px 20px 30px 20px;
  /* margin-top: 4pc; */
  border-radius: 10px;

  @media screen and (max-width: 880px) {
    display: none !important;
  }
}

.pTag {
  margin-top: 0;
  margin-bottom: 37px;
  & strong {
    color: #000000;
  }
  @media screen and (max-width: 1640px) {
    font: normal normal normal 16px/22px Nunito;
  }
}

.text1 {
  font: normal normal normal 18px/22px Nunito;
  letter-spacing: 0px;
  color: #0000006e;
  @media screen and (max-width: 1640px) {
    font: normal normal normal 16px/18px Nunito;
  }
}

.title1 {
  margin: 0 0 10px;
  font: normal normal bold 50px/71px Poppins;
  letter-spacing: 0px;
  color: #000000;
  @media screen and (max-width: 1640px) {
    font: normal normal bold 30px/41px Poppins;
  }
}

.email > input {
  border: none;
}

.pic {
  width: 100%;
  /* max-width: 860px; */
  object-fit: contain;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.box {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 30px;
  width: 100%;
  max-width: 450px;
  height: auto;
  /* max-height: 400px; */
  padding: 5px 35px 59px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding-bottom: 49px;
  }
}

.title {
  margin: 57px 0 15px;
  font: normal normal 500 31px/47px Poppins;
  letter-spacing: 0px;
  color: #04060af7;
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
    font: normal normal 700 20px/27px Nunito;
    letter-spacing: 0px;
    color: #000000;
    margin: 45px 0 15px;
  }
}

.subtitle {
  text-align: left;
  font: normal normal 400 16px/25px Roboto;
  letter-spacing: 0px;
  color: #a1a1a1;
  @media screen and (max-width: 768px) {
    text-align: center;
    font: normal normal normal 14px/19px Nunito;
    letter-spacing: 0px;
    margin: 0;
  }
}

.button {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 28px;
  margin-top: 20px;
  min-height: 55px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.mobile {
  background-image: linear-gradient(to right, #080808, #14213d);
  height: 86px;
  margin-bottom: 0px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 0 10px 10px;
  align-self: center !important;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobileText {
  color: white;
  font: normal normal bold 28px/38px Nunito;
  letter-spacing: 0px;
  color: #ffffff;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 15px;
  gap: 40px;
  flex: 1;
  @media screen and (max-width: 1300px) {
    gap: 19px;
    padding: 0 0px;
  }
}

.carBox {
  width: 90px;
  height: 80px;
  /* UI Properties */
  background: #302d2d05 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;

  @media screen and (max-width: 1300px) {
    /* display: none; */
    width: 53px;
    height: 50px;
  }
}

.car {
  width: 50px;
  height: 50px;
  opacity: 0.4;
  @media screen and (max-width: 1300px) {
    opacity: 0.2;
  }
}
