.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */
.container {
  padding: 30px 24px;
  width: calc(100% - 158px);
  margin: 0px 0px 0px 158px;
  background-color: var(--matterColorBright);
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
    padding: 112px 34px 30px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: flex-start;
  }

  @media screen and (max-width: 767px) {
    margin: 0px auto !important;
    width: 100% !important;
  }
}


.staticPage {
  & .container {
    padding: 0px;

    @media (--viewportMedium) {
      width: calc(100% - 158px);
      margin: 0px 0px 0px 158px;
      padding: 112px 34px 30px;
    }

    @media (--viewportLarge) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media screen and (max-width: 767px) {
      margin: 0px auto !important;
      width: 100% !important;
    }
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {}
}
