@import '../../styles/propertySets.css';

.root {
  width: calc(100%-158px);
  margin-left: 158px;
  background-color: white;
  padding-top: 30px;

  @media (max-width: 767px) {
    margin: 0 auto !important;
    width: 100% !important;
    border: 1px dashed #DEDEDE;
  }

}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {}

.footerTopContent {
  padding: 0px 24px 0;


  @media (--viewportLargeWithPaddings) {
    padding: 0px 35px 0;
  }

  @media (min-width:1280px) {
    padding: 24px 35px 0;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  @media (min-width: 1280px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  display: none;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1280px) {
    display: block;
    width: auto;
  }
}

.logoLink {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }

  @media (min-width:1280px) {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #E8E8E8;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 175px;
}

.organizationInfo {
  display: flex;
  flex-direction: row;

  @media (max-width:767px) {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 25px;
  }

  &>* {
    margin-right: 20px;

    @media (--viewportXLarge) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.organizationDescription,
.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  margin-top: 24px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-bottom: 24px;
  }
}

.infoLinks1 {
  order: 2;
  /* display: none; */

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-bottom: 24px;
    /* display: block; */
  }

}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;
  line-height: 100%;

  @media (--viewportMedium) {
    margin-bottom: 9px;
  }

  &:first-child {
    margin-bottom: 10px;

    @media (--viewportLarge) {
      margin-bottom: 15px;
    }

    @media (--viewportLargeWithPaddings) {
      margin-bottom: 25px;
    }
  }
}

.link {
  font: normal normal normal 15px/20px Nunito;
  letter-spacing: 0px;
  color: #858484;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  order: 5;
  width: 100% !important;
  margin-bottom: 24px;
  display: none;

  @media (--viewportLarge) {
    max-width: 350px;
    min-width: 350px;
    margin-bottom: 0;
  }

  /* @media (--viewportLargeWithPaddings) {
    max-width: 340px;
    min-width: 340px;
  }

  @media (--viewportXLarge) {
    max-width: 400px;
    min-width: 400px;
  } */

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }

}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.title {
  font: normal normal 800 15px/20px Nunito;
  color: var(--matterColorDark);
  margin: 0;

  @media (--viewportLarge) {
    font: normal normal 800 16px/20px Nunito;
  }

  @media (--viewportLargeWithPaddings) {
    font: normal normal 800 18px/24px Nunito;
  }
}

.photo {
  width: 16px;
  height: 16px;

  @media (--viewportXLarge) {
    width: 20px;
    height: 20px;
  }
}

.linkedin {
  background: #0a66c2 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  @media (--viewportXLarge) {
    width: 42px;
    height: 42px;
  }
}

.twitter {
  background: #0099ff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  @media (--viewportXLarge) {
    width: 42px;
    height: 42px;
  }
}

.instagram {
  background: #e4405f 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  @media (--viewportXLarge) {
    width: 42px;
    height: 42px;
  }
}

.facebook {
  background: #1877f2 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  @media (--viewportXLarge) {
    width: 42px;
    height: 42px;
  }
}

.input {
  color: #858484a6;
  font-size: 14px;
}

.box {
  box-shadow: 0px 3px 13px #00000008;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.subtitle {
  width: 100%;
  max-width: 400px;
  font: normal normal 300 14px/21px Poppins;
  letter-spacing: 0px;
  color: #858484;
}

.otherQuickLinks {
  flex-grow: 1;

  @media (min-width:1280px) {
    padding-left: 4%;
  }

  @media (--viewportXLarge) {
    padding-left: 8%;
  }

  & .quickLinks {
    display: flex;
    flex-wrap: wrap;

    @media (--viewportLarge) {
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    &>* {
      width: calc(100% / 2);
      padding-right: 10px;
      margin-bottom: 20px;


      @media (--viewportSmall) {
        width: calc(100% / 3);
        margin-bottom: 0px;
      }

      @media (--viewportLarge) {
        width: calc(100% / 4);
      }

      @media (--viewportSmall) {

        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      &:last-child {
        padding-right: 0px;
        margin-bottom: 0;
      }
    }
  }

  & .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #EBEBEB;
    padding: 24px 0;

    @media (max-width: 1199px) {
      justify-content: space-between;
    }

    @media (max-width: 768px) {
      display: none;
    }

    & .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1199px) {
        width: calc(100% / 3);
      }

      &>p {
        display: flex;
        align-items: center;
        margin: 0;
        font: normal normal normal 15px/20px Nunito;
        letter-spacing: 0px;
        color: #858484;
      }

      &>svg {
        width: 10px;
        margin: 0 20px 0 10px;
      }
    }

    &>svg {
      width: 10px;
      margin: 0 20px 0 10px;
    }
  }
}

.form {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  @media (--viewportLarge) {
    max-width: 400px;
  }
}

.form>input {
  border: var(--matterColorDark);
  padding: 5px 10px;
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0px;
  width: calc(100% - 130px);

  @media (--viewportXLarge) {
    font: normal normal normal 16px/25px Poppins;
  }
}

.form>input::placeholder {
  color: #858484A6 !important;
}

.form>span {
  background: #04060af7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000008;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  color: #fff;
  cursor: pointer;
  padding: 4px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 14px/20px Poppins;

  @media (--viewportXLarge) {
    padding: 4px 10px;
    font: normal normal 600 16px/25px Poppins;
    min-width: 130px;
  }
}


.subtext {
  padding: 0 10px;
  font: normal normal normal 15px/20px Nunito;
  letter-spacing: 0px;
  color: #858484;
  line-height: 100%;
  margin: 0;

  & .location {
    width: 100%;
    display: inline-block;
  }
}

.last {
  background: #ececec 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;

  @media (--viewportLargeWithPaddings) {
    padding: 16px 35px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.first {
  width: 50%;

  &>p {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #4D4B4B;
    margin: 0;
  }
}

.row1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;

  &>a {
    display: inline-block;
    margin-left: 25px;
    position: relative;
    padding-right: 25px;

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      border-radius: 10px;
      background: #CBCBCB 0% 0% no-repeat padding-box;
      width: 6px;
      height: 6px;
      content: '';
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.policy {
  font: normal normal 600 13px/16px Poppins;
  letter-spacing: 0px;
  color: #04060AF7;

  @media (min-width:480px) {
    font: normal normal 600 14px/21px Poppins;
  }
}

.mobile {
  margin: 0px;

  @media (min-width: 768px) {
    display: none !important;
  }

  &>h2 {
    text-align: left;
    font: normal normal 800 18px/24px Nunito;
    letter-spacing: 0px;
    color: var(--matterColorDark);
    margin: 30px 0 15px 0;
  }

  &>p {
    font: normal normal normal 15px/20px Nunito;
    letter-spacing: 0px;
    color: #858484;
    margin: 0 0 30px 0;

    &:last-of-type {
      margin: 0 0 15px 0;
    }
  }
}

.mobileInput {
  box-shadow: 0px 3px 13px #00000008;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 14px 20px;
  margin-bottom: 10px;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  height: 50px;
  color: #0a0a0a87;

  &:hover,
  &:focus {
    border-color: #e3dfdf;
  }
}

.mobileInput::placeholder {
  color: #85848487 !important;
}

.mobileButton {
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #04060AF7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000008;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  padding: 8px 20px;
  border: none;
  height: 50px;
}

.mobileCopyright {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding: 20px;

  @media (min-width:580px) {
    padding: 30px;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>p {
    margin: 0 0 20px 0;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #707070;
  }
}

.mobileRow {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &>a {
    display: inline-block;
    position: relative;
    margin-left: 15px;
    padding-right: 15px;

    @media (min-width:580px) {
      margin-left: 25px;
      padding-right: 25px;
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      border-radius: 10px;
      background: #CBCBCB 0% 0% no-repeat padding-box;
      width: 6px;
      height: 6px;
      content: '';
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.mobileInfo {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 20px 50px;
  margin-bottom: 0;
}

.hr {
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #dedede;
  width: 100%;
}

.hr1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dashed #dedede;
  width: 100%;
}
