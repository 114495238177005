@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  background-color: var(--matterColorLight);
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  width: auto;
  height: auto;
  min-height: 0;
  padding: 10px 20px;
  cursor: pointer;
  letter-spacing: 0px;
  color: #A7A7A7;
  font: normal normal normal 14px/18px Nunito;
  padding: 8px 16px;

  @media (--viewportLarge) {
    padding: 10px 20px;
    font: normal normal normal 16px/22px Nunito;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: var(--matterColorLight);
    border: 1px solid #E6E6E6;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
    font: normal normal normal 14px/18px Nunito;
    padding: 8px 16px;

    @media (--viewportLarge) {
      padding: 10px 20px;
      font: normal normal normal 16px/22px Nunito;
    }
  }
}

.labelSelected {
  outline: none;
  background-color: var(--matterColorDark);
  border-color: transparent;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
  border: 1px solid var(--marketplaceColor);
  letter-spacing: 0px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  border-radius: 5px;
  cursor: pointer;
  font: normal normal normal 14px/18px Nunito;
  padding: 8px 16px;

  @media (--viewportLarge) {
    padding: 10px 20px;
    font: normal normal normal 16px/22px Nunito;
  }

  &:hover,
  &:focus {
    border: 1px solid var(--matterColorDark);
    background-color: var(--matterColorDark);
    color: var(--matterColorLight);
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;

  &>label {
    font: normal normal 600 14px/20px Nunito;
    letter-spacing: 0px;
    color: #000000;
    font-weight: var(--fontWeightSemiBold);
    padding: 10px 0;
    margin: 0 18px 0 0;
    display: flex;
    align-items: center;
  }
}

.fieldPlain {
  font: normal normal 600 14px/20px Nunito;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
  padding: 16px 0 30px 20px;
  border: none;
}
