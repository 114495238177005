.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);

  @media (min-width: 320px) {
    border-radius: 15px;
  }
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  width: 100%;
  height: 100%;
}

.defaultMapLayout {
  position: relative !important;
  height: 50vh !important;
}

.defaultMapLayout .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  text-align: center !important;
  margin: 15px auto !important;
  border-radius: 10px !important;
}

.defaultMapLayout .mapboxgl-ctrl-top-right {
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto !important;
  text-align: center !important;
  width: 100% !important;
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body>.reusableMapHidden {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  display: none;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}

@media (max-width: 767px) {
  .defaultMapLayout {
    position: relative !important;
    top: 0px !important;
    right: 0 !important;
    height: 50vh !important;
  }

  .reusableMap canvas {
    position: absolute !important;
    width: 100% !important;
    height: auto !important;
  }

  #searchMap {
    border-radius: 15px !important;
  }
}
