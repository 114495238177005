.whatsappButton {
  position: fixed;
  bottom: 80px; /* Changed from 20px to 30px */
  right: 20px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25D366;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.whatsappButton:hover {
  transform: scale(1.1);
}

.whatsappButton img {
  width: 35px;
  height: 35px;
}


