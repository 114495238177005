.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.box {
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 10px;
  padding: 20px 30px;
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  color: #00000078;
}

.priceInput>input {
  background: #f3f3f3;
  border-radius: 23px;
  padding-left: 10px;
  padding-right: 5px;
}

.submitButton {
  margin-top: 50px;
  background: var(--marketplaceColor);
  border-radius: 10px;

  @media (--viewportLarge) {
    display: inline-block;
    max-width: 241px;
    margin-top: 100px;
  }

  @hover {
    transform: scale(1.05);
  }

}

.instantBooking {
  margin-top: 2pc;
  color: #00000078;
}

.instantBooking>select {
  background: #f3f3f3;
  border-radius: 23px;
  padding-left: 10px;
  color: #00000078;
}

.rowBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.errorMessage {
  color: red;
  margin-bottom: 20px;
  margin-top: 8px;
}

.inputBox {
  width: 100%;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    width: calc(50% - 10px);
    margin-bottom: 0px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & input {
    background: #f3f3f3;
    border-radius: 10px;
    padding: 13px 16px;
    height: 56px;
  }

  & select {
    background: #f3f3f3;
    border-radius: 10px;
    padding: 13px 16px;
    height: 56px;
    position: relative;
    background-image: url('../../assets/downarrow.svg') !important;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 24px;
    background-size: 3%;
  }

  & label {
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: var(--matterColorDark);
  }
}
