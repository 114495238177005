.root {
  display: flex;
  justify-content: space-between;
}
.icon {
  font-size: 35px;
}
.label {
  margin: 0;
  text-transform: uppercase;
}
.navigationLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--matterColor);
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorDark);
  }
}
.navigationLinkSelected {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--marketplaceColorDark);
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorDark);
  }
}
