@import '../../styles/propertySets.css';

.root {
  /* @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  } */
  margin: 0px 20px 20px 35px;
  /* background-color: #ffffff; */
  @media screen and (max-width: 1640px) {
    margin: 0px 0px 20px 0px;
  }
  @media screen and (max-width: 768px) {
    margin: 5px 0px;
  }
}

.mobile {
  background-image: linear-gradient(to right, #080808, #14213d);
  height: 86px;
  margin-bottom: 0px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 0 10px 10px;
  align-self: center !important;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobileText {
  color: white;
  font: normal normal bold 28px/38px Nunito;
  letter-spacing: 0px;
  color: #ffffff;
}

.password {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 28px;
  padding: 5px 10px 10px 10px;
  margin-top: 20px;
}

.password > input {
  border: none;
}

.pic {
  width: 100%;
  /* max-width: 860px; */
  object-fit: contain;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.title1 {
  margin: 30px 0 10px;
  font: normal normal bold 50px/71px Poppins;
  letter-spacing: 0px;
  color: #000000;
  @media screen and (max-width: 1640px) {
    font: normal normal bold 30px/41px Poppins;
  }
}

.text1 {
  font: normal normal normal 18px/22px Nunito;
  letter-spacing: 0px;
  color: #0000006e;
  @media screen and (max-width: 1640px) {
    font: normal normal normal 16px/18px Nunito;
  }
}

.pTag {
  margin-top: 0;
  margin-bottom: 37px;
  & strong {
    color: #000000;
  }
  @media screen and (max-width: 1640px) {
    font: normal normal normal 16px/22px Nunito;
  }
}

.box {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 30px;
  width: 100%;
  /*
  height: 100%;
  max-height: 510px; */
  padding: 5px 35px 59px;

  @media screen and (max-width: 1920px) {
    max-width: 470px;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    padding: 5px 22px;
    max-height: 85%;
    height: auto;
  }
}

.title {
  margin-top: 30px;
  text-align: center;
  font: normal normal normal 30px/39px Roboto;
  letter-spacing: 0px;
  color: #222643;
}

.subtitle {
  text-align: center;
  font: normal normal normal 18px/24px Nunito;
  letter-spacing: 0px;
  color: #979797;
}

.button {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 28px;
  margin-top: 35px;
  min-height: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.passwordRestimg {
  text-align: center;
  margin: 20px auto 0;
  display: block;
}

.rowWrapper {
  display: flex;
  gap: 58px;
  @media screen and (min-width: 1921px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
    gap: 45px;
  }
  & .inputWrapper {
    margin-top: 64px;
    @media screen and (max-width: 1640px) {
      max-width: 50%;
    }
    @media screen and (max-width: 1023px) {
      max-width: 100%;
    }
    @media screen and (max-width: 1023px) {
      margin-top: 0px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    & .text1 {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 36px;
      font-weight: 600;
    }
  }
}

.formDetailsRight {
  position: relative;
  /* background: #F5F5F5 0% 0% no-repeat padding-box; */
  @media screen and (min-width: 1921px) {
    max-width: 60%;
  }
  @media screen and (max-width: 1640px) {
    max-width: 50%;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
  & > div {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -16%;
    bottom: 0;
    background: #f5f5f5;
    transform: rotate(83deg);
    height: 1400px;
    width: 1400px;
    @media screen and (max-width: 1640px) {
      left: 8%;
    }
  }
}

.carBox {
  width: 90px;
  height: 80px;
  /* UI Properties */
  background: #302d2d05 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;

  @media screen and (max-width: 1300px) {
    /* display: none; */
    width: 53px;
    height: 50px;
  }
}

.car {
  width: 50px;
  height: 50px;
  opacity: 0.4;
  @media screen and (max-width: 1300px) {
    opacity: 0.2;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  gap: 40px;
}
.error {
  color: red;
}
