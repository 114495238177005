@import '../../styles/propertySets.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

@media (max-width: 767px) {
  .sideNav {
    display: none;
  }
}

.tabsContainer {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 30px 24px;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
    padding: 110px 34px 30px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: flex-start;
  }

}

.nav {
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorBright);
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (--viewportLarge) {
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    width: 20%;
    align-items: flex-end;
    padding: 30px;
  }
}

.tab {
  white-space: nowrap;
  padding-right: 20px;

  @media (--viewportLarge) {
    margin-bottom: 20px;
    padding-right: 0px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.panel {
  box-shadow: 0 0 3px #ddd;
  background-color: var(--matterColorLight);
  padding: 30px;
  width: 100%;

  @media (min-width:1280px) {
    width: 80%;
  }

  @media (min-width:1450px) {
    width: 70%;
  }

  @media (min-width:1650px) {
    width: 60%;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
