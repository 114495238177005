.contactDetailsSection {
    box-shadow: 0 0 3px #ddd;
    background-color: var(--matterColorLight);
    padding: 30px;
    /*width: 60%;*/

    @media(max-width: 1440px) {
        /*width: 80%;*/
    }

    @media(max-width: 1300px) {
        width: 100%;
    }

    @media(max-width: 768px) {
        padding: 24px;
    }
}

.formRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    @media(max-width: 560px) {
        flex-wrap: wrap;
    }

    & .inputRow {
        width: calc(50% - 9px);

        @media(max-width: 560px) {
            width: 100%;

            &:nth-child(1) {
                margin-bottom: 22px;
            }
        }
    }
}

.submitButton {}

.formRowSecond {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    @media(max-width: 768px) {
        flex-wrap: wrap;
    }

    & label {
        height: 32px;
        display: flex;
        align-items: flex-end;

        @media(max-width: 768px) {
            height: unset;
            display: unset;
            align-items: unset;
        }
    }

    & .inputRow {
        width: calc(50% - 9px);

        @media(max-width: 768px) {
            width: 100%;
        }
    }

    & select {
        background-position: 96% 13px
    }
}

.lastRow {
    & .inputRow {
        margin-bottom: 22px;
    }

    & select {
        background-position: 98% 13px
    }
}

.checkBoxWrapper {
    & ul {
        display: flex;
        flex-wrap: wrap;
        column-gap: 35px;
        row-gap: 0;
    }
}
