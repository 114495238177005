@import '../../styles/propertySets.css';

.inputRootNoBorders {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0);

  /* Override react-dates default styles to match input styles */

  & :global(.DateRangePicker) {
    display: block;
  }

  & :global(.DateRangePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 100% !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    background-color: var(--marketplaceColor);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    padding: 0 14px;
    margin: 0;
    border: 0;
    height: 70%;
    align-self: flex-end;
    background: none;
    font-size: 16px;
    line-height: 24px;
    color: var(--marketplaceColor);
    font-family: var(--fontFamily2);
    font-weight: bold;

    @media (min-width:1800px) {
      font-size: 20px;
      line-height: 27px;
      padding: 0 24px;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: var(--marketplaceColor);
      font-family: var(--fontFamily2);

      @media (min-width:1800px) {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    justify-content: normal;
    border: none;
    background: none;
  }

  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorLight);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DateInput) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0 2px 0;

    @media (--viewportMedium) {
      padding: 6px 0 9px 0;
    }
  }

  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorLight);
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 400;
  }

  & :global(.DayPicker__withBorder) {
    border-radius: 0;
  }

  & :global(.CalendarMonth_caption) {
    color: var(--matterColorLight);
    @apply --marketplaceH2FontStyles;
    margin: 1px 0 14px;
    font-weight: 400;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  & :global(.CalendarDay) {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 34px;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }

  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    transition: all 0.2s ease-out;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_start) {
    background-color: transparent;
    background-image: none;
  }

  & :global(.CalendarDay__selected_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    border-top-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered_start) {
    background-color: transparent;
  }

  & :global(.CalendarDay__after-hovered_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_end) {
    background-color: transparent;
  }

  & :global(.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    border-top-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    color: var(--matterColorLight);
  }

  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
    cursor: pointer;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--matterColor);
    border: 0;
    cursor: not-allowed;
  }

  & :global(.CalendarDay__blocked_out_of_range:hover .renderedDay),
  & :global(.CalendarDay__blocked_calendar:hover .renderedDay) {
    cursor: not-allowed;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--matterColor);
    border: 0;
    cursor: not-allowed;
  }

  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }

  & :global(.DateInput_fang) {
    display: none;
  }

  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput_input__disabled) {
    font-style: normal;
  }
}

.inputRoot {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0);
  border: 1px solid rgb(203, 203, 203);
  border-radius: 5px;
  padding-bottom: 24px;
  /* Override react-dates default styles to match input styles */

  & :global(.DateRangePicker) {
    display: block;
  }

  & :global(.DateRangePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 42px !important;
    background-color: var(--marketplaceColor);
    width: 100%;

    @media (--viewportMedium) {
      min-height: calc(100vh - 252px);
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 42px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    font: normal normal normal 14px/19px Nunito;
    letter-spacing: 0px;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
  }

  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorLight);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DateInput) {
    display: block;
    width: 100%;
    padding: 5px 0 2px 0;

    @media (--viewportMedium) {
      padding: 6px 0 9px 0;
    }
  }

  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorLight);
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 400;
  }

  & :global(.DayPicker__withBorder) {
    border-radius: 0;
  }

  & :global(.CalendarMonth_caption) {
    color: var(--matterColorLight);
    @apply --marketplaceH2FontStyles;
    margin: 1px 0 14px;
    font-weight: 400;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  & :global(.CalendarDay) {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 34px;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }

  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    transition: all 0.2s ease-out;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_start) {
    background-color: transparent;
    background-image: none;
  }

  & :global(.CalendarDay__selected_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    border-top-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered_start) {
    background-color: transparent;
  }

  & :global(.CalendarDay__after-hovered_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_end) {
    background-color: transparent;
  }

  & :global(.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    border-top-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    color: var(--matterColorLight);
  }

  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }

  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }

  & :global(.DateInput_fang) {
    display: none;
  }

  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput_input__disabled) {
    font-style: normal;
  }
}

.inputRootNoBorders .DateRangePickerInput.DateRangePickerInput_1 {
  justify-content: normal;
  padding: 0 !important;
  width: 100% !important;
  display: inline-block;
  margin-top: 11px;
}

.inputRootNoBorders .DateInput.DateInput_1 {
  width: 50% !important;
  display: inline-block !important;
  max-width: 13.5pc !important;
  margin-right: 25px !important;
}

.inputRootNoBorders .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
  display: none !important;
}

/*.DateRangePickerInput.DateRangePickerInput_1 {
    padding: 0;
    width: 100% !important;
    display: inline-block !important;
}*/

/*.DateInput.DateInput_1 {
    width: 50% !important;
    display: inline-block;
    margin-right: 17px !important;
    margin-top: 11px !important;
    max-width: 14pc !important;
}*/

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.DateRangePickerInput) {
    width: 100%;
    margin: 0;
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    background: none;
  }

  & :global(.DateInput:first-of-type) {
    margin-right: 12px;

    @media (--viewportMedium) {
      margin-right: 24px;
    }
  }
}

.arrowIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

.inputRootNoBorders .DateRangePicker {
  display: block;
}

.dateRangeLabel {
  display: flex;

  &>div {
    display: flex;
    align-items: center;
    position: absolute;
    top: 5px;
    z-index: 1;
    height: auto !important;
    width: 98%;

    @media (min-width:1800px) {
      top: 10px;
    }

    &>label {
      width: calc(100% / 2 - 10px);
      font: normal normal normal 13px/18px Nunito;
      letter-spacing: 0px;
      color: #A2A2A2;
      opacity: 0.54;
      margin: 3px 0;
      padding: 0 14px;

      @media (min-width:1800px) {
        padding: 0 24px;
      }

      &:last-child {
        padding: 0 10px;

        @media (min-width:1800px) {
          padding: 0 20px;
        }
      }
    }
  }
}

.bookingPanelForm {
  & .dateRangeLabel {
    margin-bottom: 10px;

    &>div {
      position: relative;
      width: 100%;

      &>label {
        font: normal normal normal 14px/19px Nunito;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        padding: 0px;
      }
    }
  }

  &>div {
    &:last-child {
      &>div {
        &>div {
          &>div {

            &:first-child,
            &:last-child {
              height: auto !important;
              width: calc(50% - 12px);
              padding: 0px !important;
              margin: 0 !important;

              &>input {
                background: #FFFFFF !important;
                border: 1px solid #DEDEDE !important;
                border-radius: 5px;
                width: 100% !important;
                padding: 0px 15px !important;
                height: 40px !important;
              }
            }
          }
        }
      }
    }
  }
}
