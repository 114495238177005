@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionContainer2 {
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionTitle {
  color: var(--matterColorDark);
  font: normal normal bold 18px/28px Nunito;
  margin: 0 0 20px 0;
  text-align: left;
  width: 100%;
}

.sectionTitle1 {
  color: var(--matterColorAnti);

  margin-top: 0;
  /* margin-bottom: 12px; */
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    /* margin-bottom: 24px; */
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000008;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  /* width: 380px; */
  min-height: 140px;
  padding: 0 10px;
}

.message > textarea {
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" strokeLinecap="round" strokeLinejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  color: var(--matterColor);
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  width: 100%;
  margin: 0;
  text-align: left;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
}

.firstName {
  width: 49%;
}

.firstName > input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding-left: 10px;
}

.lastName {
  width: 49%;
}

.lastName > input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding-left: 10px;
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
  background: var(--marketplaceColorDark);
  border-radius: 10px;
  /*width: 100%;*/
  padding: 10px 30px;
  max-width: 500px;
}

.driverLicence {
  display: block;
  background-color: #fcfcfc;
  height: 10pc;
  width: 20pc;
  border: 2px dashed #e7e7e7;
  border-radius: 10px;
  text-align: center;
  padding-top: 4pc;
  cursor: pointer;
}

.verifiedLabel {
  color: var(--successColor);
  margin-bottom: -3pc;
}

.loadingLabel {
  color: var(--marketplaceColorLight);
  margin-bottom: -3pc;
}
.field {
  margin: 1rem 0px;
}
