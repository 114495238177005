.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }

  &>ul {
    display: flex;
    flex-wrap: wrap;

    &>li {
      width: calc(100% / 1);
      margin-bottom: 10px;
      padding: 0;

      @media (--viewportSmall) {
        width: calc(100% / 2);
      }

      @media (--viewportLarge) {
        width: calc(100% / 3);
      }
    }
  }
}

.box {
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 10px;
  padding: 20px 30px;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: 50px;
  background: var(--marketplaceColor);
  border-radius: 10px;

  @media (--viewportLarge) {
    display: inline-block;
    max-width: 241px;
    margin-top: 100px;
  }

  @hover {
    transform: scale(1.05);
  }

}

.box {}
