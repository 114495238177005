.root {
  width: 100%;
  position: relative;
  border-top: 1px dashed var(--matterBorderColor);
  /*position: absolute;
    left: 0;
    right: 0;
    height: 100vh;
    margin: 50px auto 0px;
    z-index: 999999999;
    bottom: 0;
    top: 100%;*/
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #fff;
  height: auto;
  min-height: 100vh;
  position: relative;
}
