@import '../../styles/propertySets.css';

.root {
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  font: normal normal bold 20px/28px Nunito;
  letter-spacing: 0px;
  color: #000000;
  margin: 0 0 20px 0;

  @media (--viewportSmall) {
    font: normal normal bold 24px/30px Nunito;
  }

  @media (--viewportLarge) {
    font: normal normal bold 28px/38px Nunito;
  }
}



/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
