@import '../../styles/propertySets.css';

.root {
  margin: 20px 0;
}

.button {
  border: none;
  background-color: var(--marketplaceColor);
  color: white;
  padding: 2px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.buttonLong {
  border: none;
  background-color: var(--marketplaceColor);
  color: white;
  padding: 2px 20px;
  border-radius: 111px;
  cursor: pointer;
  width: 100%;
  min-height: 50px;
  font-size: 14px;
  /* margin: 0 24px 0 24px; */

  /* @media (--viewportMedium) {
    margin: 0 24px 0 24px;
  } */

  @media (--viewportLarge) {
    /* margin: 0 48px 0 48px; */
    padding: 0 0 2px 0;
  }

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.buttonLongDisabled {
  border: none;
  background-color: rgb(190, 190, 190);
  color: white;
  padding: 5px 10px;
  border-radius: 111px;
  cursor: pointer;
  width: 100%;
  min-height: 50px;
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 0 48px 0 48px;
    padding: 0 0 2px 0;
  }
}

.buttonDisabled {
  display: none;
  background-color: rgb(190, 190, 190);
  color: white;
  padding: 5px 10px;
  border-radius: 111px;
  min-width: 100px;
  pointer-events: none;
}

.topPhotosWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.uploadPhotoLabel {
  margin-top: 0px;
  margin-bottom: 10px;
  color: var(--matterColorDark);
  text-align: left;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  display: inline-block;
  width: 100%;
}

.uploadPhotoUnderlabel {
  color: var(--marketplaceColorLight);
  text-align: left;
  display: inline-block;
  width: 100%;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  font-size: 14px;
  color: var(--matterColorAnti);
  line-height: 24px;
}

.imageItem {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  border-bottom: 1px solid rgb(202, 202, 202);
  padding-bottom: 10px;
}

.image {
  height: 100%;
  width: 50%;
  object-fit: cover;
  border-radius: 5px;
  display: block;
}

.imageButtonWrapper {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  align-content: center;

  @media screen and (max-width: 520px) {
    width: 45%;
  }
}

.uploadToStWrapper {
  display: flex;
  justify-content: center;
}

.vehiclePhotoUpload {}
