@import '../../styles/propertySets.css';

.root {
  background-color: var(--matterColorLight);
  @apply --marketplaceModalBaseStyles;
  flex-basis: 576px;
  box-shadow: 0px 0px 18px #0000003d;
  border-radius: 20px;
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  font: normal normal 600 20px/28px Poppins;
  letter-spacing: 0px;
  color: var(--matterColorDark);
  margin: 0 0 20px 0;
  width: 100%;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  margin: 0 0 15px 0;
  font: normal normal normal 16px/25px Nunito;
  letter-spacing: 0px;
  color: #919191;

  &:last-child {
    margin-bottom: 0;
  }
}
