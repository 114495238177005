@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  background-color: var(--matterColorLight);
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  width: auto;
  height: auto;
  min-height: 0;
  cursor: pointer;
  letter-spacing: 0px;
  color: #A7A7A7;
  font: normal normal normal 14px/18px Nunito;
  padding: 8px 16px;

  @media (--viewportLarge) {
    padding: 10px 20px;
    font: normal normal normal 16px/22px Nunito;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: var(--matterColorLight);
    border: 1px solid #E6E6E6;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
    font: normal normal normal 14px/18px Nunito;
    padding: 8px 16px;

    @media (--viewportLarge) {
      padding: 10px 20px;
      font: normal normal normal 16px/22px Nunito;
    }
  }
}

.labelSelected {
  outline: none;
  background-color: var(--matterColorDark);
  border-color: transparent;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
  border: 1px solid var(--marketplaceColor);
  letter-spacing: 0px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  border-radius: 5px;
  cursor: pointer;
  font: normal normal normal 14px/18px Nunito;
  padding: 8px 16px;

  @media (--viewportLarge) {
    padding: 10px 20px;
    font: normal normal normal 16px/22px Nunito;
  }

  &:hover,
  &:focus {
    border: 1px solid var(--matterColorDark);
    background-color: var(--matterColorDark);
    color: var(--matterColorLight);
  }
}
