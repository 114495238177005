.root {
  display: flex;
  flex-direction: column;
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.field > input {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding-left: 10px;
  border-bottom: none !important;
}

.field > select {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding-left: 10px;
  border-bottom: none !important;
}
