@import '../../styles/propertySets.css';

.root {

  & p,
  & ul li {
    font: normal normal normal 18px/22px Nunito;
    letter-spacing: 0px;
    color: #808080;
    margin: 0 0 20px 0;
    list-style: none;
  }

  &>ul {
    padding-left: 20px;
  }

  & h3 {
    font: normal normal bold 18px/22px Nunito;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    margin: 0 0 20px 0;
  }

  & h2 {
    font: normal normal bold 18px/22px Nunito;
    letter-spacing: 0px;
    color: #333333;
    margin: 0 0 50px 0;
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 14px;
  }
}
