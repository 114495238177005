@import '../../styles/propertySets.css';

/* Animation for the hero elements */

.root {
  width: 100%;
  height: 100%;
  background-image: url('./Enmascarar.png'), linear-gradient(107deg, #d8ddeb 0%, #fbf6ef 100%);
  background-position: center;
  background-blend-mode: multiply;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;

  @media (min-width: 768px) {
    padding: 0px 20px 0px 40px;
  }

  @media (min-width: 1280px) {
    padding: 0px 20px 0px 70px;
  }
}

.mobile {
  width: 100%;
  height: auto;
  object-fit: contain;

  @media (max-width: 768px) {
    display: none;
  }
}

.heroContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }

  & .heroLeftContent {
    width: 100%;
    padding: 20px 0 40px;
    text-align: center;

    @media (--viewportMedium) {
      padding: 20px 0;
    }

    @media (--viewportLarge) {
      width: 50%;
      text-align: left;
    }

    & .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        margin: 10px auto;
        align-items: center;
      }

      & .rentalo {
        width: 100%;
        max-width: 165px;
        height: 50px;

        @media (min-width: 1440px) {
          height: 68px;
          max-width: 210px;
        }
      }

      & .heading {
        font-size: 26px;
        line-height: 40px;
        font-weight: var(--fontWeightBold);
        letter-spacing: 0px;
        padding-right: 5px;
        color: var(--matterColorDark);
        font-family: var(--fontFamily);
        margin: 0;

        @media (min-width: 1440px) {
          font-size: 36px;
          line-height: 55px;
        }
      }
    }

    & .content {
      color: #00000094;
      line-height: 22px;
      width: 100%;
      font-family: var(--fontFamily2);
      text-align: left;
      margin: 0;

      @media (min-width: 1280px) {
        max-width: 90%;
      }

      @media (max-width: 768px) {
        margin: 10px 0px 40px;
        width: 100%;
      }
    }

    & .publica {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 100%;
      justify-content: center;
      margin: 0px auto;

      @media (--viewportMedium) {
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: flex-start;
      }

      &>a {
        display: inline-block;
        width: 80%;

        @media (--viewportSmall) {
          width: 60%;
        }

        @media (--viewportMedium) {
          width: auto;
        }
      }

      & .listButton {
        background-color: var(--marketplaceColorDark);
        color: var(--matterColorLight);

        border-radius: 100px;
        border: solid 1px var(--marketplaceColorDark);
        opacity: 1;
        font-size: 16px;
        line-height: 25px;
        opacity: 1;
        cursor: pointer;
        padding: 9px 30px;
        z-index: 2;

        @media (min-width:1440px) {
          padding: 9px 50px;
        }

        @media (max-width: 768px) {
          margin: 0 auto;
          width: 100%;
        }

        &:hover {
          background: var(--matterColorDark) 0% 0% no-repeat padding-box;
          border: solid 1px var(--matterColorDark);
        }
      }

      & .cursor {
        height: 45px;
        max-width: 200px;
        width: 100%;
        padding-left: 10px;
        display: none;

        @media (min-width: 1440px) {
          height: 55px;
        }

        @media (--viewportLarge) {
          display: block;
        }
      }
    }
  }

  & .heroImageSec {
    position: relative;
    width: 100%;

    @media (--viewportLarge) {
      bottom: -70px;
      width: 50%;
    }
  }
}