@import '../../styles/propertySets.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 20px;
    margin-top: 0;
    margin-bottom: 0;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
  }

  @media (min-width: 1600px) {
    padding: 20px 30px;
  }

  @media (max-width: 767px) {
    padding: 24px;
    padding-top: 50px;
    border-radius: 0;
  }
}

.modalHeading {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 30px;
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }

  @media (max-width: 767px) {
    padding-bottom: 100px;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 20px;
  width: 100%;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
    margin-top: 20px;
  }
}

.bookingDatesSubmitButtonWrapper > button {
  background-color: var(--marketplaceColorDark);
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: none;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    width: calc(100vw - 158px);
    margin-left: auto;
    display: none;
  }

  @media (--viewportLarge) {
  }
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-right: 22px;
}

.priceValue {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);
  margin-top: 0;
  margin-bottom: 0px;
}

.perUnit {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0px;
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  border-radius: 100px;
  color: var(--matterColorLight);
  min-height: 50px;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.infoSections {
  @media (max-width: 900px) {
    display: none;
  }

  & .infoSection {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 25px 0;
    padding: 30px;
  }

  & .infoSectionTitle {
    text-align: left;
    letter-spacing: 0px;
    color: var(--matterColorDark);
    font: normal normal 500 21px/31px Poppins;
    margin: 0 0 20px 0;
  }

  & .infoSectionBody {
    letter-spacing: 0px;
    color: var(--matterColor);
    font: normal normal normal 16px/22px Nunito;
    margin: 0 0 20px 0;
  }

  & .infoSectionLearnMore {
    color: var(--marketplaceColorDark);
    cursor: pointer;

    & > svg {
      margin-left: 15px;
      width: 12px;
    }
  }
}

.modalContent {
  & > h3 {
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: none;
  }

  & > h4 {
    font: normal normal bold 16px/22px Nunito;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
  }

  & > p {
    margin: 0 0 15px 0;
    font: normal normal normal 16px/22px Nunito;
    letter-spacing: 0px;
    color: #7a7a7a;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.extendTripModal {
  & > div {
    &:first-child {
      display: block;
    }
  }

  & .modalContainer {
    border: none;
    padding: 0;
    margin: 0;

    @media (max-width: 767px) {
      height: auto;
      width: 100%;
    }
  }
}
