@import '../../styles/propertySets.css';

.root {
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.classes {
  /* @media (max-width:768px) {
    display: flex; 
    flex-direction: column;
  } */
  /* margin-left: 20px; */
}

.ourClientsSec {
  flex-direction: column;
  display: flex;
  align-items: center;

  @media (min-width: 1300px) {
    flex-direction: row;
  }

  & .ourClientsInfo {
    width: 100%;
    text-align: center;

    @media (min-width: 1300px) {
      width: 50%;
      text-align: left;
    }
  }

  & .ourClients {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1300px) {
      width: 50%;
    }

    & > * {
      display: inline-block;
      width: calc(100% / 5 - 20px);
      margin: 0 30px;
      text-align: center;

      &:first-child {
        & > img {
          max-width: 100%;
          margin: 0;
          width: 40px;
          min-width: 40px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      & > img {
        max-width: 100%;
        margin: 0;
        min-width: 70px;
      }
    }
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.change {
  margin-left: 30px;

  @media (max-width: 768px) {
    margin: 5px 10px;
  }
}

.heading {
  font-size: 28px;
  font-weight: 600;
  color: var(--matterColorDark);
  margin-bottom: 2px;
  line-height: 38px;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: var(--fontFamily2);

  @media (max-width: 959px) {
    text-align: center;
  }
}

.pTag {
  font-size: 16px;
  color: var(--matterColor);
  margin: 0px;
  font-weight: 400;
  line-height: 22px;
  font-family: var(--fontFamily2);

  @media (max-width: 959px) {
    text-align: center;
    margin-bottom: 40px;
  }
}

.buttonWrapper {
  width: 30vw;
  margin-top: 3pc;

  @media only (max-width: 800px) {
    width: 100%;
  }
}

.image {
  width: 70px;
}
.tabs {
  width: 70%;
  max-width: 70%;
  margin: 0 auto;
  @media (--viewportMedium) {
    width: 50%;
  }
}
