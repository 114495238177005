.root {
  display: flex;
  flex-direction: column;
  /* margin-top: var(--topbarHeight); */
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.footer {
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 9px 24px;
    z-index: 999;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowTop);
  }
}
.space {
  margin: 0;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
}
