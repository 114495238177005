@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceH5FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  z-index: 0;

  & :global(.CalendarMonth_caption) {
    @apply --marketplaceH3FontStyles;
    font-weight: var(--fontWeightMedium);
    text-align: left;
    padding-top: 18px;
    padding-bottom: 55px;

    margin-left: 97px;
    margin-top: 0;
    margin-bottom: 0;

    & strong {
      font-weight: 500;
      letter-spacing: 0.2px;
    }
  }

  & :global(.DayPicker) {
    margin: 0 auto;
    box-shadow: none;
  }

  & :global(.DayPicker__horizontal) {
    background-color: transparent;
    margin-left: -18px;
  }

  & :global(.DayPicker_weekHeader) {
    top: 65px;

    & small {
      font: normal normal normal 16px/24px Nunito;
      letter-spacing: 0px;
      color: var(--matterColorDark);
    }
  }

  & :global(.DayPicker_weekHeader_li) {
    text-align: left;
  }

  & :global(.DayPickerNavigation__horizontal) {
    width: 80px;
    margin-left: 18px;
    position: relative;

    & :first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2px;
    }

    & :last-child {
      /* The navigation arrows have 9px padding. Add -9px margin to
      align the arrows with the calendar */
      left: 35px;
      right: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 0;
    }
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 13px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--matterColorNegative);
    background-color: var(--matterColorBright);

    &:hover {
      background-color: var(--ManageAvailabilityCalendar_availableColorHover);

      & svg {
        fill: var(--matterColorDark);
      }
    }

    &:focus {
      outline: none;
      background-color: var(--ManageAvailabilityCalendar_availableColorHover);

      & svg {
        fill: var(--matterColorDark);
      }
    }
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.CalendarMonth) {
    background-color: transparent;
  }

  & :global(.CalendarMonth_table) {
    border: 1px solid var(--ManageAvailabilityCalendar_gridColor);
  }

  & :global(.CalendarDay__default) {
    border: 0;
    background-color: var(--ManageAvailabilityCalendar_gridColor);

    &:hover {
      border: 0;
      background-color: var(--ManageAvailabilityCalendar_gridColor);
    }
  }

  & :global(.CalendarDay__selected) {
    color: var(--matterColor);
  }

  & :global(.DayPickerKeyboardShortcuts_show__bottomRight) {
    right: -20px;
    right: 19px;
    bottom: -24px;
  }
}

.dayWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.day {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_availableColorHover);
  }
}

.dayNumber {
  font: normal normal normal 16px/24px Nunito;
  letter-spacing: 0px;
  color: var(--matterColorDark);
}

.default {
  composes: day;
  background-color: var(--ManageAvailabilityCalendar_availableColor);
}

.outsideRange {
  background-color: var(--ManageAvailabilityCalendar_blockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  }

  & .dayNumber {
    text-decoration: line-through;
    color: lightgrey;
  }
}

.today {
  background-color: var(--ManageAvailabilityCalendar_availableColor);

  & .dayNumber {
    position: relative;
    text-decoration: none;

    /* underline */
    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      right: 0;
      bottom: -4px;
      left: 0;
      margin: 0 auto;
      background-color: var(--matterColorDark);
    }
  }
}

.blocked {
  background-color: var(--ManageAvailabilityCalendar_blockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  }
}

.reserved {
  background-color: var(--ManageAvailabilityCalendar_reservedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_reservedColorHover);
  }
}

.inProgress {
  width: 12px;
  height: 12px;
  stroke: var(--matterColor);
  strokeWidth: 3px;
}

.exceptionError {
  opacity: 1;

  /* Animation */
  animation-name: errored;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes errored {

  30%,
  70% {
    background-color: var(--ManageAvailabilityCalendar_failedColor);
  }
}

.monthElement {
  display: flex;
  position: relative;
  top: 3px;
}

.monthString {
  font: normal normal 600 20px/24px Nunito;
  letter-spacing: 0px;
  color: var(--matterColorDark);

  &:first-letter {
    text-transform: uppercase;
  }
}

.monthInProgress {
  width: 12px;
  height: 12px;
  margin-top: 7px;
  margin-left: 8px;

  stroke: var(--marketplaceColor);
  strokeWidth: 4px;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 6px 0 24px 0;
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColor);
  fill: var(--matterColor);
}

.legend {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.legendRow {
  display: flex;
  flex-grow: row;
  line-height: 24px;
  margin-right: 18px;
  margin-bottom: 6px;
}

.legendColor {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-top: 2px;
  margin-right: 8px;
}

.legendAvailableColor {
  composes: legendColor;
  background-color: var(--matterColorLight);
  border: solid 2px var(--matterColorNegative);
}

.legendReservedColor {
  composes: legendColor;
  background-color: var(--ManageAvailabilityCalendar_reservedColor);
}

.legendBlockedColor {
  composes: legendColor;
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
}

.legendText {
  @apply --marketplaceH4FontStyles;
  white-space: nowrap;
  margin: 0;
}

@media (--viewportSmall) {

  /* day aka table cell content should have bigger paddings when there's more space */
  .day {
    padding: 16px;
  }

  .dayNumber {
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: var(--matterColorDark);
  }
}

/* Standard break points */
@media (--viewportMedium) {
  .root {
    margin-top: 0;
    margin-bottom: 0;

    & :global(.CalendarMonth_caption) {
      font-size: 24px;
      padding-bottom: 62px;
    }

    & :global(.DayPicker_weekHeader) {
      top: 67px;
    }

    & :global(.DayPickerNavigation__horizontal) {
      & :last-child {
        /* The navigation arrows have 9px padding. Add -9px margin to
        align the arrows with the calendar */
        left: 39px;
      }
    }

    & :global(.DayPickerNavigation_button__horizontal) {
      width: 40px;
      height: 40px;
    }
  }

  .inProgress {
    width: 16px;
    height: 16px;
  }

  .monthInProgress {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }

  .error {
    margin: 8px 0 24px 0;
  }

  .legendRow {
    margin-right: 24px;
    margin-bottom: 8px;
  }

  .legendText {
    margin: 0;
  }
}

@media (--viewportLarge) {
  .root {
    & :global(.DayPickerNavigation_button__horizontal) {
      background-color: var(--matterColorLight);
    }
  }

  .legend {
    margin: 0;
  }
}

@media (--viewportXLarge) {
  .root {
    & :global(.CalendarMonth_caption) {
      margin-left: 108px;
    }
  }
}
