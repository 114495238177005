@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageTitle {
  text-align: center;
}

@media (max-width: 767px) {
  .sideNav {
    display: none;
  }
}

.staticPageWrapper {
  width: 100%;
  margin: 0px 0px 0px 158px;
  background-color: white;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
  }

  @media screen and (max-width: 767px) {
    margin: 0px auto !important;
    width: 100% !important;
  }
}

.mobile {
  background-image: linear-gradient(to right, #080808, #14213d);
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-self: center !important;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobileText {
  font: normal normal bold 28px/38px Nunito;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.contentWrapper {
  padding: 30px 24px;
  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    padding: 150px 24px 50px;
  }

  @media (min-width:1500px) {
    padding: 150px 34px 50px;
  }
}


.content {
  width: 100%;
  text-align: center;
  margin: 0 auto;

  @media (--viewportMedium) {
    max-width: 587px;
    width: 80%;
  }
}

.number {
  font: normal normal bold 75px/93px Poppins;
  letter-spacing: 0px;
  color: var(--matterColorDark);
  margin: 0 0 20px 0;
}

.heading {
  font: normal normal bold 25px/35px Nunito;
  letter-spacing: 0px;
  color: var(--matterColorDark);
  margin: 0 0 10px 0;

  @media (--viewportMedium) {
    font: normal normal bold 30px/37px Nunito;
  }
}

.description {
  font: normal normal normal 18px/24px Nunito;
  letter-spacing: 0px;
  color: var(--matterColor);
  margin: 0;
}

.searchForm {
  max-width: 408px;
  width: 100%;
  margin: 40px auto 0;
}
