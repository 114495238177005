.root {
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;

}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  margin: 0;
  border: 1px solid var(--matterBorderColor);
  background: #f3f3f3;
  border-radius: 10px;
  padding: 13px 16px;

  &:hover,
  &:focus {
    border-color: var(--matterColorAnti);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: calc(100% + 0px);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  & ul {
    & li {
      font: normal normal normal 16px/24px Nunito;
      letter-spacing: 0px;
      color: var(--matterColorLight);
    }
  }
}

.validLocation {
  border-color: var(--matterColorAnti);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;
  color: #00000078;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.building>input {
  background: #f3f3f3;
  border-radius: 23px;
  border-bottom: none !important;
  padding-left: 10px;
  padding-right: 5px;
}

.submitButton {
  margin-top: 50px;
  background: var(--marketplaceColor);
  border-radius: 10px;

  @media (--viewportLarge) {
    display: inline-block;
    max-width: 241px;
    margin-top: 100px;
  }

  @hover {
    transform: scale(1.05);
  }
}

.box {

  /* background: #f8f8f8;
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 10px; */
  & label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3d3d3d;
    margin-bottom: 5px;
  }

  & input {
    background: #f3f3f3;
    border-radius: 10px;
    padding: 13px 16px;
    height: 56px;

    @media (max-width: 1500px) {
      height: 40px;
    }
  }

  & .locationMainBox {
    margin-bottom: 30px;
  }
}

.category {
  color: #00000078;
}

.category>select {
  background: #f3f3f3;
  border-radius: 23px;
  padding-left: 10px;
  color: #00000078;
}

.inputBox {
  &>label {
    font: normal normal normal 16px/24px Nunito;
    letter-spacing: 0px;
    color: var(--matterColorDark);
  }
}
