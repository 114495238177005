/*.DateInput_1 {
  background-color: white !important;
  border: none !important;
  border-radius: 10px;
  height: 70% !important;
  margin: auto 0 !important;
  padding: 0 !important;
  padding-left: 15px !important;
  max-width: 13.5pc !important;
}*/

.DateInput_1 {
  background-color: white !important;
  border: none !important;
  border-radius: 10px;
  height: 55px !important;
  margin: auto 10px 0 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.DateInput.DateInput_1:last-child {
  margin-right: 0px !important;
}

.FieldDateRangeInput_input__U8j_Z {
  display: none !important;
}

.DateInput_1>input {}

/* .DateRangePickerInput{
    height: 100% !important;
}

.DateRangeInput_withMobileMargins__y-7Q-{
    height: 100% !important;
}

.DateRangePicker_1{
    height: 100% !important;
}

.DateRangePicker_1 > div{
    height: 100% !important;
} */
@media (min-width:1800px) {

  .DateInput_1 {
    height: 64px !important;
  }
}
